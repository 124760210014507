import './App.css';
import { useState, useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Nav from 'react-bootstrap/Nav';
import moment from 'moment-timezone';
import Button from 'react-bootstrap/Button';


const formatTime = (isoString, timezone) => moment.utc(isoString).tz(timezone).format('YYYY-MM-DD hh:mm:ss A')


const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

const JSON_KEY_TO_PRETTY_TITLE = {
  "Player": "Player",
  "Match": "Match",
  "Time": "Time " + timeZone,
  "Team": "Team",
  "Stat": "Stat",
  "UD_Line": "Underdog Line",
  "PP_Line": "PrizePicks Line",
  "Discrep": "Discrepancy"
}

function App() {
  const [data, setData] = useState(null);
  const [errors, setErrors] = useState(null);

  const [selectedMatches, setSelectedMatches] = useState(null);

  const [currentLeague, setCurrentLeague] = useState(null)

  const [availableLeagues, setAvailableLeagues] = useState(null)

  const [isFetching, setIsFetching] = useState(false)

  const [sortStatus, setSortStatus] = useState({
    "Player": "bi bi-dash",
    "Match": "bi bi-dash",
    "Time": "bi bi-dash",
    "Team": "bi bi-dash",
    "Stat": "bi bi-dash",
    "UD_Line": "bi bi-dash",
    "PP_Line": "bi bi-dash",
    "Discrep": "bi bi-dash"
  })

  const fetchData = (path) => {
    setIsFetching(true)
    fetch(`https://api.jackho.ng/${path}`)
    .then(response => 
      {
        if (response.status === 200){
          response.json().then(json => {
            setData(json)
            setAvailableLeagues(json.leagues)
            setCurrentLeague(json.leagues[0])
          })

        }
        else{
          response.json().then(json => setErrors(json))
        }
      }).finally(() => setIsFetching(false))
  }

  useEffect(() => {
    fetchData("cs2")
  }, []);



  if(!data && !errors){
  return (
    <div className="App">
      <header className="App-header">
        <Spinner animation="border" role="status">
        </Spinner>
          Fetching Data
      </header>
    </div>
    )
  }

  if(errors){
    console.log(errors)
    return (
      <div className="App">
        <header className="App-header">
          {errors.detail.toString()}
        </header>
      </div>
      )
  }

  if(data){
    return (
      <div className="App">
        <header className="App-header">
          <div className="RequestInfo">
            {"Generated at " +formatTime(data.request_time, timeZone)}
            <br></br>
            {data.cache_info && data.cache_info.map(cache_info =>
            (<><a> {"Fetched " + cache_info.league + " at " + formatTime(cache_info.at, timeZone)}</a> <Button disabled={isFetching} onClick={() => fetchData(cache_info.league)}> {isFetching ? "Fetching Data" : "Refetch Data"} </Button><br></br></>)
            )}
          </div>
          {(availableLeagues && availableLeagues[0] && availableLeagues[0][0] && data[availableLeagues[0]][0]) && <div className="Table">
          <Nav variant="tabs" defaultActiveKey={availableLeagues[0]}>
          {availableLeagues.map(league =>
          <Nav.Item key={league}>
            <Nav.Link onClick={() => setCurrentLeague(league)}eventKey={league}>{league}</Nav.Link>
          </Nav.Item>
          )}
          </Nav>
         <Table striped bordered hover>
          <thead>
            <tr className="header">
              {Object.keys(data[availableLeagues[0]][0]).map(title =>
                <th key={title} style={{cursor: 'pointer', minWidth: "180px"}} onClick={() => {
                    setSortStatus((prevState) => {
                      if (prevState[title] === "bi bi-dash"){
                        let newState = {...prevState}
                        Object.keys(newState).forEach(v => newState[v] = "bi bi-dash")
                        newState[title] = "bi bi-arrow-up-short"
                        return newState
                      }
                      else if (prevState[title] === "bi bi-arrow-up-short"){
                        let newState = {...prevState}
                        Object.keys(newState).forEach(v => newState[v] = "bi bi-dash")
                        newState[title] = "bi bi-arrow-down-short"
                        return newState
                      }
                      else if (prevState[title] === "bi bi-arrow-down-short"){
                        let newState = {...prevState}
                        Object.keys(newState).forEach(v => newState[v] = "bi bi-dash")
                        newState[title] = "bi bi-arrow-up-short"
                        return newState
                    }})

                    setData((prevState) => {
                      if (sortStatus[title] === "bi bi-dash"){
                        let newState = {...prevState}
                        newState[currentLeague].sort((a, b) => a[title].localeCompare(b[title]));
                        return newState
                      }
                      else if (sortStatus[title] === "bi bi-arrow-up-short"){
                        let newState = {...prevState}
                        newState[currentLeague].sort((a, b) => b[title].localeCompare(a[title]));
                        return newState
                      }
                      else if (sortStatus[title] === "bi bi-arrow-down-short"){
                        let newState = {...prevState}
                        newState[currentLeague].sort((a, b) => a[title].localeCompare(b[title]));
                        return newState
                      }
                    });
                  }
                }>
                  <a>{JSON_KEY_TO_PRETTY_TITLE[title]}</a>
                  <i style={{paddingLeft:'10px'}} key={title + "status"} className={sortStatus[title]}></i>
                  </th>
              )}
              <th> Select Match</th>
            </tr>
          </thead>
          <tbody>
            {data[currentLeague].map((row, i) =>{

            let rowStyle;


            if (selectedMatches && row.Match in selectedMatches && (row.Player !== selectedMatches[row.Match].Player || row.Stat != selectedMatches[row.Match].Stat))
            {
              rowStyle = {color: 'black', textDecorationLine: 'line-through', textDecorationStyle: 'solid'}
            }
            else if (selectedMatches && row.Match in selectedMatches && (row.Player === selectedMatches[row.Match].Player && row.Stat === selectedMatches[row.Match].Stat))
            {
              rowStyle = {color: 'green', textDecorationLine: 'underline', textDecorationStyle: 'solid'}
            }

            return(  <tr style={rowStyle} key={i}>
                  <td style={{minWidth: '190px'}} key={i + row.Player}>{row.Player}</td>
                  <td style={{minWidth: '190px'}} key={i + row.Match}>{row.Match}</td>
                  <td style={{minWidth: '190px'}} key={i + row.Time}>{formatTime(row.Time, timeZone)}</td>
                  <td style={{minWidth: '190px'}} key={i + row.Team}>{row.Team}</td>
                  <td style={{minWidth: '190px'}} key={i + row.Stat}>{row.Stat}</td>
                  <td style={{minWidth: '190px'}} key={i + row.UD_Line}>{row.UD_Line}</td>
                  <td style={{minWidth: '190px'}} key={i + row.PP_Line}>{row.PP_Line}</td>
                  <td style={{minWidth: '190px'}} key={i + row.Discrep}>{row.Discrep}</td>
                  <td>
                    {!rowStyle && <Button style={{minHeight: '94px', minWidth:'94px'}} onClick={ () => {
                      setSelectedMatches((prevState) => {
                        let newState = {...prevState}
                        newState[row.Match] = {Player: row.Player, Stat: row.Stat}
                        return newState
                      });}
                    }>
                      
                      Select Match
                      </Button>}
                    {rowStyle && rowStyle.color === 'green' && <Button style={{minHeight: '94px', minWidth:'94px'}} variant="outline-secondary" className={"arrow dash"} onClick={ () => {
                      setSelectedMatches((prevState) => {
                        let newState = {...prevState}
                        delete newState[row.Match]
                        return newState
                      });}
                    } >
                     Unselect Match 
                      </Button>}

                      {rowStyle && rowStyle.color !== 'green' && <div style={{minHeight: '94px', minWidth:'94px'}}>
                      </div>}

                  </td>
              </tr>)
            }
            )}
          </tbody>
        </Table> 
        </div>}
        </header>
      </div>
    );
  }
}

export default App;
